
/* generated by srcipts/compressFont.js */

@font-face {
  font-family: HYWenHei-85W;
  src: url('./HYWenHei-85W.eot');
  src:
    url('./HYWenHei-85W.eot') format('embedded-opentype'),
    url('./HYWenHei-85W.woff2') format('woff2'),
    url('./HYWenHei-85W.woff') format('woff'),
    url('./HYWenHei-85W.ttf') format('truetype'),
    url('./HYWenHei-85W.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: HYWenHei-65W;
  src: url('./HYWenHei-65W.eot');
  src:
    url('./HYWenHei-65W.eot') format('embedded-opentype'),
    url('./HYWenHei-65W.woff2') format('woff2'),
    url('./HYWenHei-65W.woff') format('woff'),
    url('./HYWenHei-65W.ttf') format('truetype'),
    url('./HYWenHei-65W.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

