@import '~antd/dist/antd.css';
@import './font/font.css';

body {
  font-family: HYWenHei-65W, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}
.ant-layout {
  background-color: #f9f9f9;
}
